<template>
  <div class="centerwrap">
    <div class="centerbox">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
        <el-breadcrumb-item :to="{ path: '/order' }"
          >交易列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>绑定提现银行卡</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="formbox">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="提现银行" prop="bank">
            <el-select v-model="ruleForm.bank" placeholder="请选提现银行">
              <el-option label="工商银行" value="1"></el-option>
              <el-option label="农业银行" value="2"></el-option>
              <el-option label="中国银行" value="3"></el-option>
              <el-option label="建设银行" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户地址" prop="name">
            <el-input v-model="ruleForm.loc"></el-input>
          </el-form-item>
          <el-form-item label="银行卡号" prop="name">
            <el-input v-model.number="ruleForm.num"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { bandbankcard } from "@/api/project.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        bank: "",
        loc: "",
        num: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        bank: [{ required: true, message: "请选提现银行", trigger: "change" }],
        loc: [{ required: true, message: "请输入开户行", trigger: "blur" }],
        num: [{ required: true, message: "请输银行卡号", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    async bandbankcard(val) {
      try {
        const result = await bandbankcard({
          banktype: this.ruleForm.bank,
          bankaddress: this.ruleForm.loc,
          bankcard: this.ruleForm.num,
          realname: this.ruleForm.name,
        });
        if (result.status == 200) {
          this.$message({
          showClose: true,
          message: result.message||'恭喜你绑定成功',
          type: 'success'
        });
        }
        else{
            this.$message({
          showClose: true,
          message: result.message||'绑定失败',
          type: 'error'
        });
        }
      } catch (err) {}
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.bandbankcard();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative;
  padding: 0 20px;
}
.centerbox {
  width: 100%;
}

/deep/.el-menu--horizontal {
  // padding: 10px 0 0 0;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 10px;
    height: 46px;
    line-height: 32px;
    font-size: 14px;
    color: #222;
    margin-right: 20px;
    &.is-active {
      color: #16aed0;
    }
  }
}
.formbox {
  margin: 50px 0;
}
.el-form {
  width: 60%;
  min-width: 300px;
  .el-select {
    width: 100%;
  }
}
</style>